import { HttpErrorResponse } from "@angular/common/http";
import { UserModel } from "./../../../../../../core/auth/_models/user-model.model";
import { CustomerService } from "./../../customer.service";
// Angular
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
// Material
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from "@angular/material";
// RXJS
import { Subscription, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
// Translate Module
import { TranslateService } from "@ngx-translate/core";
// NGRX
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../../core/reducers";
// CRUD
import { LayoutUtilsService, MessageType } from "../../../../../../core/_base/crud";
// Services and Models
// Components
import { CustomerEditDialogComponent } from "../customer-edit/customer-edit.dialog.component";
import { CustomerHistoryDialogComponent } from "../customer-history/customer-history.dialog.component";
import { CustomerUploadDialogComponent } from "../customer-upload/customer-upload.dialog.component";

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/compgetItemCssClassByStatusonents/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
	// tslint:disable-next-line:component-selector
	selector: "kt-customers-list",
	templateUrl: "./customers-list.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: MatTableDataSource<UserModel> = new MatTableDataSource<UserModel>();
	displayedColumns = ["id", "username", "nomeAzienda", "credito", "listinoPrezzi", "abilitato", "commessa", "campagna", "actions"];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	paging: {
		maxPage: number;
		itemsForPage: number;
		maxItem: number;
	};
	loading = true;
	preloading = true;
	// Filter fields
	@ViewChild("searchInput", { static: true }) searchInput: ElementRef;
	filterStatus = "";
	filterType = "";
	// Selection
	customersResult: UserModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private store: Store<AppState>, private customerService: CustomerService) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.paginator.page)
			.pipe(tap(() => this.loadCustomersList()))
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, "keyup")
			.pipe(
				debounceTime(50),
				distinctUntilChanged(), // This operator will eliminate duplicate values
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadCustomersList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// First load
		this.loadCustomersList();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((el) => el.unsubscribe());
	}

	/**
	 * Load Customers List from service through data-source
	 */
	loadCustomersList() {
		this.loading = true;
		const searchText: string = this.searchInput.nativeElement.value;

		this.customerService.searchCustomer(searchText, this.paginator.pageIndex, this.paginator.pageSize).subscribe(
			(response: any) => {
				const customers: UserModel[] = response.result;
				this.paging = response.page;
				this.customersResult = customers;
				this.dataSource.data = this.customersResult;
				this.loading = false;
				this.preloading = false;
			},
			(error: HttpErrorResponse) => {
				console.log(error.error.message || "Errore durante il collegamento con il server, riprova.");
				this.loading = false;
				this.preloading = false;
			}
		);
	}

	/** ACTIONS */
	/**
	 * Delete customer
	 *
	 * @param _item: CustomerModel
	 */
	deleteCustomer(_item: UserModel) {
		const enableString = _item.abilitato ? "disabilitare" : "abilitare";
		const enableString2 = _item.abilitato ? "disabilitato" : "abilitato";
		const enableString3 = _item.abilitato ? "Disabilita" : "Abilita";
		const _title: string = this.translate.instant(enableString3 + " utente");
		const _description: string = this.translate.instant("Sei sicuro di voler " + enableString + " questo utente dalla piattaforma?");
		const _waitDesciption: string = this.translate.instant("Sto disabilitando l'utente..");
		const _deleteMessage = this.translate.instant("Utente " + enableString2 + " correttamente.");

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption, _item);
		dialogRef.afterClosed().subscribe((error: boolean | HttpErrorResponse) => {
			if (typeof error === "boolean" && error === false) {
				return;
			} else if (typeof error !== "boolean") {
				this.layoutUtilsService.showActionNotification("Errore durante la richiesta al server, riprova", MessageType.Delete, 3000, true, false);
				return;
			}
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete, 3000, true, false);
			this.loadCustomersList();
		});
	}

	/**
	 * Show add customer dialog
	 */
	addCustomer() {
		const newCustomer = new UserModel();
		newCustomer.clear(); // Set all defaults fields
		this.editCustomer(newCustomer);
	}

	/**
	 * Show Edit customer dialog and save after success close result
	 * @param customer: CustomerModel
	 */
	editCustomer(customer: UserModel) {
		let saveMessageTranslateParam = "Utente ";
		saveMessageTranslateParam += customer.id > 0 ? "modificato correttamente." : "creato correttamente.";
		const _saveMessage = this.translate.instant(saveMessageTranslateParam);
		const _messageType = customer.id > 0 ? MessageType.Update : MessageType.Create;
		const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer } });
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 3000, true, false);
			this.loadCustomersList();
		});
	}

	uploadFileToCustomer(customer: UserModel) {
		const dialogRef = this.dialog.open(CustomerUploadDialogComponent, { data: { customer } });
		dialogRef.afterClosed().subscribe((res) => {});
	}

	historyCustomer(customer: UserModel) {
		const dialogRef = this.dialog.open(CustomerHistoryDialogComponent, { data: { customer } });
		dialogRef.afterClosed().subscribe((res) => {});
	}

	/** UI */
	/**
	 * Retursn CSS Class Name by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: boolean): string {
		return status ? "success" : "danger";
	}

	/**
	 * Returns Item Status in string
	 * @param status: number
	 */
	getItemStatusString(status: boolean): string {
		return status ? "ABILITATO" : "DISABILITATO";
	}

	/**
	 * Returns CSS Class Name by type
	 * @param status: number
	 */
	getItemCssClassByType(status: number = 0): string {
		switch (status) {
			case 0:
				return "accent";
			case 1:
				return "primary";
			case 2:
				return "";
		}
		return "";
	}

	/**
	 * Returns Item Type in string
	 * @param status: number
	 */
	getItemTypeString(status: number = 0): string {
		switch (status) {
			case 0:
				return "Business";
			case 1:
				return "Individual";
		}
		return "";
	}
}
