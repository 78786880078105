import { UserModel } from './../../../../../../core/auth/_models/user-model.model';
import { CustomerService } from './../../customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { currentUser } from '../../../../../../core/auth';
import { environment } from '../../../../../../../environments/environment';
import { Core, Dashboard, XHRUpload } from 'uppy';

// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of, Observable } from 'rxjs';
import { TypesUtilsService, LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
import { ResponseUpload } from '../../../../dashboard/dashboard.component';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

declare var require: any;
const Italian = require('@uppy/locales/lib/it_IT');

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-customers-upload-dialog',
	styleUrls: ['./customer-upload.dialog.component.scss'],
	templateUrl: './customer-upload.dialog.component.html'
})
export class CustomerUploadDialogComponent implements OnInit, OnDestroy {
	// Public properties
	customer: UserModel;
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	serverErrorMessage: string;
	user$: Observable<UserModel>;
	uppyInstance: any;
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<CustomerUploadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private layoutUtilsService: LayoutUtilsService,
		private customerService: CustomerService,
		private cd: ChangeDetectorRef,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.customer = this.data.customer;
		this.user$ = this.store.pipe(select(currentUser));
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {

		this.instantiateUppy();

		this.customerForm = this.fb.group({
			dataScadenza: [null, Validators.compose([Validators.required, Validators.nullValidator])],
			credito: [null, Validators.compose([Validators.required])]
		});
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return `Upload file a '${this.customer.username}'`;
	}


	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.customerForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	uploadFile(dataScadenza?, credito?: number) {
		const _customer = this.customer;
		this.customerService.updateCustomer(_customer).subscribe(response => {
			this.dialogRef.close({ _customer, isEdit: true });
		}, (error: HttpErrorResponse) => {
			this.serverErrorMessage = error.error.message || 'Errore durante la creazione dell\'utente, riprova.';
			this.cd.markForCheck();
		});
	}


	instantiateUppy() {
		this.user$.subscribe(user => {
			if (user && !this.uppyInstance) {
				const config = {
					allowMultipleUploads: false,
					uploadAPI: {
						endpoint: environment.baseUrl + '/admin/uploadCSV?idCliente=' + this.customer.id,
						headers: {
							'X-Auth-Token': user.jwtToken
						}
					},
					restrictions: {
						maxNumberOfFiles: 1,
						allowedFileTypes: ['.csv']
					},
					plugins: {}
				};

				const uppy = Core({
					autoProceed: false,
					locale: Italian,
					restrictions: config.restrictions,
					onBeforeUpload: (files) => {
						return this.onBeforeUpload(files);
					}
				});
				this.uppyInstance = uppy;
				uppy.use(Dashboard,
					{
						target: '.drag-drop-area',
						inline: true,
						locale: {
							strings: {
								dropPaste: 'Trascina i file CSV con solo il numero di telefono qui, oppure %{browse}',
							}
						}
					}
				);
				uppy.use(XHRUpload, {
					endpoint: config.uploadAPI.endpoint,
					headers: config.uploadAPI.headers,
					timeout: 300 * 1000
				});
				uppy.on('file-added', file => {
					this.onFileAdd(file);
				});
				uppy.on('upload', data => {
					this.onFileUpload(data);
				});
				uppy.on('complete', result => {
					this.uploadResult(result);
				});
			}
			this.cd.markForCheck();
		});
	}

	onBeforeUpload(evt) {
		this.hasFormErrors = false;
		this.serverErrorMessage = null;
		const controls = this.customerForm.controls;
		/** check form */
		if (this.customerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.cd.markForCheck();
			return false;
		}

		const _date = controls.dataScadenza.value;
		let dataScadenza = '';
		if (_date) {
			dataScadenza = this.typesUtilsService.dateFormat(_date);
		}

		const key = Object.keys(evt)[0];

		evt[key].meta = {
			...evt[key].meta,
			credito: controls.credito.value,
			dataScadenza
		};
		return evt;
	}

	uploadResult(evt) {
		const success = evt.successful.length > 0;

		if (success) {
			const response: ResponseUpload = evt.successful[0].response.body as ResponseUpload;

			this.layoutUtilsService.showActionNotification('File caricato correttamente.', MessageType.Create, 3000, true, false);
			this.onAlertClose(null);

		} else {
			const error = evt.failed[0].response.body || null;
			this.serverErrorMessage = (error) ? error.message || 'Errore durante il caricamento del file, riprova' :
				'Errore durante il caricamento del file, riprova';
		}
		this.cd.markForCheck();
	}

	onFileAdd(evt) {
		this.cd.markForCheck();
	}

	onFileUpload(evt) {
		console.log('onFileUpload', evt);
	}



	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
