import { BaseModel } from './../../_base/crud/models/_base.model';

export class UserModel extends BaseModel {
	id: number;
	username: string;
	password: string;
	nomeAzienda: string;
	credito: number;
	listinoPrezzi: number;
	jwtToken: string;
	admin: boolean;
	dataCreazione?: Date;
	commessa?: string;
	campagna?: string;
	abilitato: boolean;
	dataScadenza?: Date;
	prezzo?: String;

	constructor(user?: UserModel) {
		super();
		if (user) {
			this.id = user.id;
			this.username = user.username;
			this.password = user.password;
			this.nomeAzienda = user.nomeAzienda;
			this.credito = user.credito;
			this.listinoPrezzi = user.listinoPrezzi;
			this.jwtToken = user.jwtToken;
			this.admin = user.admin;
			this.dataCreazione = user.dataCreazione;
			this.commessa = user.commessa;
			this.campagna = user.campagna;
			this.abilitato = user.abilitato;
			this.dataScadenza = user.dataScadenza;
			this.prezzo = user.prezzo;
		}
	}

	clear(): void {
		this.id = undefined;
		this.username = '';
		this.password = '';
		this.nomeAzienda = '';
		this.credito = undefined;
		this.listinoPrezzi = undefined;
		this.dataCreazione = undefined;
		this.commessa = undefined;
		this.campagna = undefined;
		this.abilitato = false;
		this.dataScadenza = undefined;
		this.prezzo = undefined;
	}
}
