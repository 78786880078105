import { UserModel } from './../../../../../../core/auth/_models/user-model.model';
import { CustomerService } from './../../customer.service';
import { HttpErrorResponse } from '@angular/common/http';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../../core/_base/crud';
// Services and Models
import { selectCustomersActionLoading } from '../../../../../../core/e-commerce';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-customers-edit-dialog',
	templateUrl: './customer-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class CustomerEditDialogComponent implements OnInit, OnDestroy {
	// Public properties
	customer: UserModel;
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	serverErrorMessage: string;
	isInEdit = false;
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<CustomerEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private customerService: CustomerService,
		private cd: ChangeDetectorRef,
		private typesUtilsService: TypesUtilsService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.store.pipe(select(selectCustomersActionLoading)).subscribe(res => this.viewLoading = res);
		this.customer = this.data.customer;
		this.isInEdit = this.customer.id ? true : false;
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		const passwordValidator = (!this.isInEdit) ? Validators.required : null;
		const validationListino = (this.customer.admin) ? null :  Validators.compose([Validators.required]);

		this.customerForm = this.fb.group({
			username: [{ value: this.customer.username, disabled: this.isInEdit }, Validators.required],
			password: [this.customer.password, passwordValidator],
			nomeAzienda: [{ value: this.customer.nomeAzienda, disabled: this.isInEdit }, Validators.required],
			// dob: [this.typesUtilsService.getDateFromString(this.customer.dateOfBbirth), Validators.compose([Validators.nullValidator])],
			credito: [this.customer.credito, Validators.compose([Validators.required])],
			listinoPrezzi: [{value: this.customer.listinoPrezzi, disabled: this.customer.admin}, validationListino],
			commessa: [{ value: this.customer.commessa, disabled: this.isInEdit }],
			campagna: [{ value: this.customer.campagna, disabled: this.isInEdit }]
		});
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		if (this.customer.id > 0) {
			return `Modifica utente '${this.customer.username}'`;
		}

		return 'Crea nuovo utente';
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.customerForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared customer
	 */
	prepareCustomer(): UserModel {
		const controls = this.customerForm.controls;
		const _customer = new UserModel();
		_customer.id = this.customer.id;
		// const _date = controls.dob.value;
		// if (_date) {
		// 	_customer.dateOfBbirth = this.typesUtilsService.dateFormat(_date);
		// } else {
		// 	_customer.dateOfBbirth = '';
		// }
		_customer.username = controls.username.value;
		_customer.password = controls.password.value;
		_customer.nomeAzienda = controls.nomeAzienda.value;
		_customer.credito = controls.credito.value;
		_customer.commessa = controls.commessa.value;
		_customer.campagna = controls.campagna.value;
		_customer.listinoPrezzi = controls.listinoPrezzi.value;
		return _customer;
	}

	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
		this.serverErrorMessage = null;
		const controls = this.customerForm.controls;
		/** check form */
		if (this.customerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedCustomer = this.prepareCustomer();
		if (editedCustomer.id > 0) {
			this.updateCustomer(editedCustomer);
		} else {
			this.createCustomer(editedCustomer);
		}
	}

	/**
	 * Update customer
	 *
	 * @param _customer: UserModel
	 */
	updateCustomer(_customer: UserModel) {

		this.customerService.updateCustomer(_customer).subscribe(response => {
			this.dialogRef.close({ _customer, isEdit: true });
		}, (error: HttpErrorResponse) => {
			this.serverErrorMessage = error.error.message || 'Errore durante la creazione dell\'utente, riprova.';
			this.cd.markForCheck();
		});
	}

	/**
	 * Create customer
	 *
	 * @param _customer: UserModel
	 */
	createCustomer(_customer: UserModel) {
		this.customerService.createCustomer(_customer).subscribe(response => {
			this.dialogRef.close({ _customer, isEdit: false });
		}, (error: HttpErrorResponse) => {
			this.serverErrorMessage = error.error.message || 'Errore durante la creazione dell\'utente, riprova.';
			this.cd.markForCheck();
		});
	}

	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
