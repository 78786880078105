import { UserModel } from './../_models/user-model.model';
import { currentUser } from '../_selectors/auth.selectors';
import { Observable, of } from 'rxjs';
import { AppState } from './../../reducers/index';
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// NGRX
import { select, Store } from '@ngrx/store';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private store: Store<AppState>, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store
			.pipe(
				select(currentUser),
				map((user: UserModel) => {
					return !!(user && user.admin);
				}),
				tap(canAccess => {
					if (!canAccess) {
						this.router.navigateByUrl('/');
					}
				})
			);
	}
}
