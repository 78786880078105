import { environment } from './../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
// PATH VARIABLES


@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor(private httpClient: HttpClient) {
	}

	getByUrl<T>(url: string): Observable<T> {
		return this.httpClient.get<T>(url);
	}

	getByPath<T>(path: string): Observable<T> {
		return this.httpClient.get<T>(environment.baseUrl + '' + path);
	}

	deleteByPath<T>(path: string): Observable<T> {
		return this.httpClient.delete<T>(environment.baseUrl + '' + path);
	}

	putByPath<T>(path: string): Observable<T> {
		const body = {};
		return this.httpClient.put<T>(environment.baseUrl + '' + path, body);
	}

	postByPath<T>(path: string, bodyRequest: any): Observable<T> {
		return this.httpClient.post<T>(environment.baseUrl + '' + path, bodyRequest);
	}


	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(
			'Something bad happened; please try again later.');
	}
}
