import { HttpErrorResponse } from "@angular/common/http";
import { UserModel } from "./../../../../../../core/auth/_models/user-model.model";
import { CustomerService } from "./../../customer.service";
// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
// Material
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
// RxJS
import { Subscription } from "rxjs";
// NGRX
// State
// CRUD
// Services and Models
import { environment } from "../../../../../../../environments/environment";

@Component({
	selector: "kt-customers-history-dialog",
	templateUrl: "./customer-history.dialog.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CustomerHistoryDialogComponent implements OnInit, OnDestroy {
	// Public properties
	customer: UserModel;
	hasFormErrors = false;
	viewLoading = false;
	dataSource;
	serverErrorMessage: string;
	displayedColumns = ["id", "dataUpload", "stato", "rielabora", "numeriCaricati", "numeriOk", "numeriScartati", "numeriKo", "creditoUtilizzato"];
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerHistoryDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<CustomerHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private customerService: CustomerService, private cd: ChangeDetectorRef, private snakBar: MatSnackBar) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.customer = this.data.customer;

		this.viewLoading = true;
		this.customerService.getCustomerHistory(this.customer).subscribe(
			(response) => {
				this.viewLoading = false;
				this.dataSource = response;
				this.cd.markForCheck();
			},
			(error: HttpErrorResponse) => {
				this.viewLoading = false;
				this.serverErrorMessage = error.error.message || "Errore durante la connessione con il server, riprova.";
				this.cd.markForCheck();
			}
		);
	}

	getFileState(file: any) {
		if (file.isLavorazione) {
			return "IN LAVORAZIONE";
		} else if (file.stato === true) {
			return "ACQUISITO";
		} else if (file.stato === false) {
			return "FALLITO";
		}
		return "";
	}

	getItemCssClassByStatus(file): string {
		switch (this.getFileState(file)) {
			case "IN LAVORAZIONE":
				return "warning";
			case "ACQUISITO":
				return "success";
			case "FALLITO":
				return "danger";
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return "Storico file di " + this.customer.username;
	}

	getDownloadLink(idFile: number, typeFile: "ORIGINAL" | "OK" | "KO" | "REMOVED") {
		switch (typeFile) {
			case "ORIGINAL":
				return environment.baseUrl + "/downloadCSV/originalFile?idCustomer=" + this.customer.id + "&idFileUpload=" + idFile;
			case "OK":
				return environment.baseUrl + "/downloadCSV/numeriOK?idCustomer=" + this.customer.id + "&idFileUpload=" + idFile;
			case "KO":
				return environment.baseUrl + "/downloadCSV/numeriKO?idCustomer=" + this.customer.id + "&idFileUpload=" + idFile;
			case "REMOVED":
				return environment.baseUrl + "/downloadCSV/numeriScartati?idCustomer=" + this.customer.id + "&idFileUpload=" + idFile;
		}
	}

	rework(file: any) {
		this.customerService.reworkFile(this.customer.id, file.idFileUpload).subscribe(() => {
			this.snakBar.open("Il tuo file è in stato di rielaborazione, si prega di attendere", "Chiudi", {
				duration: 5000,
				verticalPosition: "top",
				horizontalPosition: "center",
			});
			this.dialogRef.close();
		});
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
