// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/content/error-page/error-page.component";
// Auth
import { AuthGuard, AdminGuard, NotLoggedGuard } from "./core/auth";

const routes: Routes = [
	{
		path: "auth",
		canActivate: [NotLoggedGuard],
		loadChildren: () => import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "",
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: "dashboard",
				loadChildren: () => import("./views/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
			},
			{
				path: "utenti",
				loadChildren: () => import("./views/pages/apps/utenti/utenti.module").then((m) => m.UtentiModule),
			},
			{
				path: "storico",
				loadChildren: () => import("./views/pages/apps/history/history.module").then((m) => m.HistoryModule),
			},
			{
				path: "tracciato",
				loadChildren: () => import("./views/pages/apps/track/track.module").then((m) => m.TrackModule),
			},
			{
				path: "blacklist",
				loadChildren: () => import("./views/pages/apps/blacklist/blacklist.module").then((m) => m.BlacklistModule),
			},
			{
				path: "error/403",
				component: ErrorPageComponent,
				data: {
					type: "error-v6",
					code: 403,
					title: "403... Access forbidden",
					desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
				},
			},
			{ path: "error/:type", component: ErrorPageComponent },
			{ path: "", redirectTo: "dashboard", pathMatch: "full" },
			{ path: "**", redirectTo: "dashboard", pathMatch: "full" },
		],
	},

	{ path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
