// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { UserModel } from './../_models/user-model.model';

export interface AuthState {
	loggedIn: boolean;
	authToken: string;
	user: UserModel;
	isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
	loggedIn: false,
	authToken: undefined,
	user: undefined,
	isUserLoaded: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {
		case AuthActionTypes.Login: {
			const _token: string = action.payload.authToken;
			return {
				loggedIn: true,
				authToken: _token,
				user: action.payload.user,
				isUserLoaded: false
			};
		}

		case AuthActionTypes.Register: {
			const _token: string = action.payload.authToken;
			return {
				loggedIn: true,
				authToken: _token,
				user: undefined,
				isUserLoaded: false
			};
		}

		case AuthActionTypes.UserCreditUpdate: {
			const credit: number = action.payload.credit;
			const listinoPrezzi = action.payload.listinoPrezzi;
			const user: UserModel = new UserModel(state.user);
			user.credito = credit;
			if (listinoPrezzi) {
				user.listinoPrezzi = listinoPrezzi;
			}
			return {
				...state,
				user
			};
		}

		case AuthActionTypes.Logout:
			return initialAuthState;

		case AuthActionTypes.UserLoaded: {
			const _user: UserModel = action.payload.user;
			return {
				...state,
				user: _user,
				isUserLoaded: true
			};
		}

		default:
			return state;
	}
}
