import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserModel } from "./../../../../core/auth/_models/user-model.model";
import { HttpService } from "../../../../core/_base/crud/utils/http.service";
import { Injectable } from "@angular/core";

import moment from "moment";

import { environment } from "./../../../../../environments/environment";

@Injectable({ providedIn: "root" })
export class CustomerService {
	constructor(private httpService: HttpService, private http: HttpClient) {}

	updateCustomer(customer: UserModel) {
		const body = {
			credito: customer.credito,
			listinoPrezzi: customer.listinoPrezzi,
		};
		if (customer.password) {
			body["password"] = customer.password;
		}
		return this.httpService.postByPath("/admin/updateCustomer/customerId/" + customer.id, body);
	}

	getCustomerHistory(customer: UserModel) {
		return this.httpService.getByPath("/admin/getHistoryFile?idCustomer=" + customer.id);
	}

	toggleUser(customer: UserModel) {
		customer.abilitato = !customer.abilitato;
		return this.httpService.getByPath("/admin/enabledCustomer/customerId/" + customer.id + "?flag=" + customer.abilitato);
	}

	createCustomer(customer: UserModel) {
		return this.httpService.postByPath("/admin/createCustomer", customer);
	}

	searchCustomer(filter?: string, currentPage?: number, pageSize: number = 10) {
		return this.httpService.getByPath("/admin/getAllCustomerByFilter?filter=" + filter + "&currentPage=" + currentPage + "&itemsForPage=" + pageSize);
	}

	searchBlacklist(filter?: string, currentPage?: number, pageSize: number = 10) {
		return this.httpService.getByPath("/admin/getBlacklistByFilter?filter=" + filter + "&currentPage=" + currentPage + "&itemsForPage=" + pageSize);
	}

	deleteFromBlacklist(id: number) {
		return this.httpService.deleteByPath("/admin/deleteFromBlacklist/" + id);
	}

	reworkFile(idCustomer: number, idFile: number) {
		return this.httpService.getByPath("/customer/reworkFile?idCustomer=" + idCustomer + "&idFile=" + idFile);
	}

	searchPdfReport(customer: UserModel, telefono: string) {
		const headers = new HttpHeaders().set("Accept", "application/json; application/pdf; text/plain");
		return this.http.get(environment.baseUrl + "/pdfreport?telefono=" + telefono + "&idCliente=" + customer.id, { headers, responseType: "blob" as "blob" });
	}

	insertPhoneBlacklist(telefono: string) {
		return this.http.get(environment.baseUrl + "/admin/blacklist?telefono=" + telefono);
	}

	saveReport(telefono: string, nome: string, cognome: string, data: string, ora: string, ip: string, idCliente: string) {
		const finalDate = moment(data).format("DD/MM/YYYY") + " " + ora;
		return this.http.get(environment.baseUrl + "/saveReport?telefono=" + telefono + "&nome=" + nome + "&cognome=" + cognome + "&data=" + finalDate + "&indirizzoIP=" + ip + "&idCliente=" + idCliente);
	}

	getTrackRequests(customerId: number, isAdmin: boolean) {
		return this.http.get(environment.baseUrl + "/trackRequests?idCliente=" + customerId + "&isAdmin=" + isAdmin);
	}
}
